<script setup>
import { useAuthStore } from '~/store/auth.js'
import axiosProclaimService from '~/services/axios.proclaim.api.service.js'

const { $Echo } = useNuxtApp()

const isProclaimDown = computed(() => useAuthStore().isProclaimDown)

onMounted(async () => {
  $Echo?.channel('App.Proclaim.Status')?.listen('ProclaimStatusUpdated', (event) => {
    updateStatus(event.isDown)
  })

  await getProclaimStatus()
})

onBeforeUnmount(() => {
  $Echo?.leaveChannel('App.Proclaim.Status')
})

async function getProclaimStatus() {
  await axiosProclaimService.get('/status').then((response) => {
    updateStatus(response?.is_down)
  })
}

function updateStatus(isDown) {
  useAuthStore().setProclaimStatus(isDown)
}
</script>

<template>
  <banner
    v-if="isProclaimDown"
    message="<strong>Service Disruption:</strong> Our team is actively working to resolve the issue. We appreciate your patience and apologize for any inconvenience this may cause."
    type="error"
    :dismissable="false"
  />
</template>
