<script setup>
const enviroment = import.meta.env?.VITE_APP_ENV
</script>

<template>
  <p v-if="enviroment === 'local' || enviroment === 'staging'" class="text-shadow bg-primary p-2 text-center font-extrabold text-white">
    This is {{ enviroment }} environment
  </p>
</template>

<style scoped>
.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
</style>
