<script setup>
import { XMarkIcon } from '@heroicons/vue/20/solid'

defineProps({
  message: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'info',
  },
  dismissable: {
    type: Boolean,
    default: true,
  },
})

const emits = defineEmits(['dismiss'])
</script>

<template>
  <div
    :class="[
      'flex items-center gap-x-6 px-6 py-2.5 sm:px-3.5',
      {
        'bg-blue-50 text-blue-700': type === 'info',
        'bg-yellow-50 text-yellow-800': type === 'warning',
        'bg-red-50 text-red-800': type === 'error',
        'bg-green-50 text-green-800': type === 'success',
        'sm:before:flex-1': dismissable,
        'justify-center': !dismissable,
      },
    ]"
  >
    <div class="text-sm leading-6" v-html="message" />
    <div v-if="dismissable" class="flex flex-1 justify-end">
      <button type="button" class="-m-3 p-3 focus-visible:outline-offset-[-4px]" @click="emits('dismiss')">
        <span class="sr-only">Dismiss</span>
        <XMarkIcon class="h-5 w-5" aria-hidden="true" />
      </button>
    </div>
  </div>
</template>
